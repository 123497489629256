@media (min-width:801px) {
    .main-container {
        height: 100vh;
        padding-top: 0;
    }
}

.main-container {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
}
